import React, {useState} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from "@material-ui/core/Collapse";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Header from "./Header";
import {Link} from "react-router-dom";
import {useQuery, gql} from '@apollo/client';

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0
        },
        drawerPaper: {
            width: drawerWidth
        },
        drawerContainer: {
            overflow: 'auto'
        },
        toolbar: theme.mixins.toolbar,
        nested: {
            paddingLeft: theme.spacing(4),
        },
        siteName: {
            padding: theme.spacing(2)
        }
    }),
);

function ListItemLink(props) {
    return <ListItem button component={Link} {...props} />;
}

const Sidebar = () => {
    const classes = useStyles();

    const {loading, data} = useQuery(gql`
        query {
            solutions {
                id
                title
                slug
                type
            }
            feedbackUrl{
                link
            }
        }
    `)

    const [awsSolutionsOpen, setAwsSolutionsOpen] = useState(true);
    const [partnerSolutionsOpen, setPartnerSolutionsOpen] = useState(true);
    const [otherSolutionsOpen, setOtherSolutionsOpen] = useState(true);

    const awsSolutionsOnClick = () => {
        setAwsSolutionsOpen(!awsSolutionsOpen)
    };

    const partnerSolutionsOnClick = () => {
        setPartnerSolutionsOpen(!partnerSolutionsOpen)
    };

    const otherSolutionsOnClick = () => {
        setOtherSolutionsOpen(!otherSolutionsOpen)
    };

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
        >
            <div className={classes.toolbar}>
                <div className={classes.siteName}><Header size={'h5'}/></div>
            </div>
            <Divider/>
            <List>
                <ListItem>
                    <ListItemLink to={'/'}>
                        <ListItemText primary="Getting Started" primaryTypographyProps={{variant: 'body1'}}/>
                    </ListItemLink>
                </ListItem>

                <Divider/>

                <ListItem>
                    <ListItemLink to={'/solutions/list'}>
                        <ListItemText primary="All Solutions" primaryTypographyProps={{variant: 'body1'}}/>
                    </ListItemLink>
                </ListItem>

                <ListItem onClick={awsSolutionsOnClick} button>
                    <ListItemText primary="AWS Solutions" primaryTypographyProps={{variant: 'body1'}}/>
                    {awsSolutionsOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={awsSolutionsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {loading ? null :
                            data.solutions.filter(s => s.type === 'AWS').map((v, i) => (
                                    <ListItemLink key={i} to={`/solutions/${v.id}/${v.slug}`}
                                                  className={classes.nested}>
                                        <ListItemText primary={v.title}
                                                      primaryTypographyProps={{variant: 'body2'}}/>
                                    </ListItemLink>
                                )
                            )}
                    </List>
                </Collapse>

                <ListItem onClick={partnerSolutionsOnClick} button>
                    <ListItemText primary="Partner Solutions" primaryTypographyProps={{variant: 'body1'}}/>
                    {partnerSolutionsOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={partnerSolutionsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {loading ? null :
                            data.solutions.filter(s => s.type === 'Partner').map((v, i) => (
                                    <ListItemLink key={i} to={`/solutions/${v.id}/${v.slug}`}
                                                  className={classes.nested}>
                                        <ListItemText primary={v.title}
                                                      primaryTypographyProps={{variant: 'body2'}}/>
                                    </ListItemLink>
                                )
                            )}
                    </List>
                </Collapse>

                <ListItem onClick={otherSolutionsOnClick} button>
                    <ListItemText primary="Other Solutions" primaryTypographyProps={{variant: 'body1'}}/>
                    {otherSolutionsOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={otherSolutionsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {loading ? null :
                            data.solutions.filter(s => s.type === 'Other').map((v, i) => (
                                    <ListItemLink key={i} to={`/solutions/${v.id}/${v.slug}`}
                                                  className={classes.nested}>
                                        <ListItemText primary={v.title}
                                                      primaryTypographyProps={{variant: 'body2'}}/>
                                    </ListItemLink>
                                )
                            )}
                    </List>
                </Collapse>
            </List>
        </Drawer>
    )
}

export default Sidebar;
import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        marginTop: 64
    },
    toolbar: theme.mixins.toolbar,
}))

const Content = ({children}) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.toolbar}/>
            <main className={classes.content}>
                {children}
            </main>
        </>

    )
}

export default Content;
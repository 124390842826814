import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from "@material-ui/core/Button";
import AppBar from '@material-ui/core/AppBar';
import {gql, useQuery} from "@apollo/client";
import config from "../config";

const useStyles = makeStyles({
    appBar: {
        zIndex: 1201,
        width: 'calc(100% - 240px)',
        marginLeft: '240px',
    }
});

const Navbar = () => {
    const classes = useStyles();

    const {loading, data} = useQuery(gql`
        query {
            feedbackUrl{
                link
            }
        }
    `)

    return (
        <AppBar position="fixed" color='primary' className={classes.appBar}>
            <Toolbar>
                <div style={{flexGrow: 1}}/>
                <Button target='_blank' href={`https://${config.apiHostname}/admin`}
                        color="inherit"><strong>Admin Panel</strong></Button>
                <Button target='_blank' href={loading ? '' : data.feedbackUrl.link || ''}
                        color="inherit"><strong>Feedback</strong></Button>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar;
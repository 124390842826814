import React, {useEffect, useState} from 'react';
import Amplify, {Auth, Hub} from 'aws-amplify';
import config from '../config';
import {ApolloProvider} from "@apollo/client";
import createClient from "../graphql/client";

Amplify.configure(config.Amplify);

const AmazonFederateAuthenticator = ({children}) => {
    const [user, setUser] = useState(null);
    const [client, setClient] = useState(null);

    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then(userData => (userData))
            .catch(() => console.log('Not signed in'));
    }

    useEffect(() => {
        Hub.listen('auth', ({payload: {event, data}}) => {
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    getUser().then(userData => {
                        setUser(userData);
                        setClient(createClient(userData.signInUserSession.accessToken.jwtToken))
                    });
                    break;
                case 'signOut':
                    setUser(null);
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    console.log('Sign in failure', data);
                    break;
                default:
                    console.log(`Unknown event - ${event}`);
                    break;
            }
        });

        Auth.currentAuthenticatedUser().then(userData => {
            setUser(userData);
            setClient(createClient(userData.signInUserSession.accessToken.jwtToken))
        }).catch(() => Auth.federatedSignIn({customProvider: config.cognitoOidcProviderName}));
    }, [])

    return user && client ? <ApolloProvider client={client}>{children}</ApolloProvider> : null;
}

export default AmazonFederateAuthenticator;

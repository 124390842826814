import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import TablePagination from "@material-ui/core/TablePagination";
import Chip from "@material-ui/core/Chip";
import {FaAws} from 'react-icons/fa';
import {useHistory} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";
import {GrWorkshop} from "react-icons/gr";
import SEO from "../SEO";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
    pagination: {
        marginBottom: theme.spacing(2)
    }
}));

const ListSolutions = ({location}) => {
    const history = useHistory();
    const query = new URLSearchParams(location.search);
    const _rowPerPage = parseInt(query.get('rowPerPage') || '10', 10);
    const _page = parseInt(query.get('page') || '0', 10);

    const [page, setPage] = useState(_page);
    const [rowsPerPage, setRowsPerPage] = useState(_rowPerPage);

    const {loading, data} = useQuery(gql`
        query {
            solutions(limit: ${rowsPerPage}, start: ${rowsPerPage * page}){
                id
                title
                slug
                description
                type
                cover {
                    formats
                }
            }
            solutionsConnection {
                aggregate {
                    count
                }
            }
        }
    `)

    const handleChangePage = (event, newPage) => {
        history.push(`/solutions/list?page=${newPage}&rowPerPage=${rowsPerPage}`);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        history.push(`/solutions/list?page=${page}&rowPerPage=${parseInt(event.target.value, 10)}`);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCardOnClick = (id, slug) => {
        history.push(`/solutions/${id}/${slug}`);
    }

    const classes = useStyles();
    return (
        <>
            <SEO title='All Solutions'/>
            <div className={classes.pagination}>
                <TablePagination
                    component="div"
                    count={loading ? 0 : data.solutionsConnection.aggregate.count}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
            <Grid container spacing={3}>
                {loading ? null : data.solutions.map((solution, i) => (
                    <Grid item xs={3} key={i}>
                        <Fade in={true} timeout={400 + i * 60}>
                            <Card>
                                <CardActionArea onClick={() => handleCardOnClick(solution.id, solution.slug)}>
                                    <CardMedia
                                        component="img"
                                        image={solution.cover?.formats?.thumbnail?.url || 'https://demos.hkg.sa.aws.dev/upload/temp_image_300x224_09486edcc2.jpg'}
                                        title={solution.title}
                                        height="260"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {solution.title}
                                        </Typography>
                                        {solution.type === 'AWS' &&
                                        <Chip size='small' label="AWS Solution" avatar={<FaAws/>}
                                              variant='outlined'
                                              style={{paddingLeft: '.4rem', marginBottom: '.5rem'}}/>}
                                        {solution.type === 'PARTNER' &&
                                        <Chip size='small' label="Partner Solution" avatar={<GrWorkshop/>}
                                              variant='outlined'
                                              style={{paddingLeft: '.4rem', marginBottom: '.5rem'}}/>}
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {solution.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                {/*<CardActions>*/}
                                {/*    <Button size="small" color="secondary">*/}
                                {/*        Share*/}
                                {/*    </Button>*/}
                                {/*    <Button size="small" color="secondary">*/}
                                {/*        Learn More*/}
                                {/*    </Button>*/}
                                {/*</CardActions>*/}
                            </Card>
                        </Fade>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default ListSolutions;
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import Content from "./components/Content";
import CssBaseline from "@material-ui/core/CssBaseline";
import ListSolutions from "./components/solution/ListSolutions";
import Solution from "./components/solution/Solution";
import ReactMarkdown from 'react-markdown';
import {gql, useQuery} from '@apollo/client';
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";
import SEO from "./components/SEO";

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            '"Nunito"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {main: "#232F3E"},
        secondary: {main: "#FF9900"}
    }
});


function App() {

    const {loading, data} = useQuery(gql`
        query {
            introduction{
                content
            }
        }
    `)

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <div style={{display: 'flex'}}>
                    <SEO/>
                    <Navbar/>
                    <Sidebar/>
                    <CssBaseline/>
                    <Content>
                        <Switch>
                            <Route path="/solutions/:id/:slug"
                                   component={Solution}/>
                            <Route path="/solutions/list"
                                   component={({location}) => <ListSolutions location={location}/>}/>
                            <Route path="/"><ReactMarkdown
                                source={loading ? '' : data.introduction.content || ''}/></Route>
                            <Route path="/404">
                                <Alert severity="error">
                                    <AlertTitle>404 Page not found <span role='img'
                                                                         aria-label='sad'>😢</span></AlertTitle>
                                    Maybe the page you are looking for has been removed, or you typed in the wrong
                                    URL.
                                </Alert>
                            </Route>
                            <Redirect to="/404"/>
                        </Switch>
                    </Content>
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;

export default {
    siteTitle: process.env.REACT_APP_SITE_TITLE || 'AWS HKG SA Demo Hub',
    siteDescription: process.env.REACT_APP_SITE_DESCRIPTION || 'AWS HKG SA Demo Hub Description',
    webHostname: process.env.REACT_APP_WEB_HOSTNAME || 'demos.hkg.sa.aws.dev',
    apiHostname: process.env.REACT_APP_API_HOSTNAME || 'api.demos.hkg.sa.aws.dev',
    cognitoOidcProviderName: process.env.REACT_APP_COGNITO_OIDC_PROVIDER_NAME || 'AmazonFederate',
    // https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
    Amplify: {
        Auth:{
            region: process.env.REACT_APP_AWS_REGION,
            userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
            userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
            mandatorySignIn: true,
            cookieStorage: {
                domain: `.${process.env.REACT_APP_WEB_HOSTNAME || 'demos.hkg.sa.aws.dev'}`
            },
            oauth:{
                domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
                scope: ['openid', 'email'],
                redirectSignIn: [`https://${process.env.REACT_APP_WEB_HOSTNAME || 'demos.hkg.sa.aws.dev'}`],
                redirectSignOut: [`https://${process.env.REACT_APP_WEB_HOSTNAME || 'demos.hkg.sa.aws.dev'}`],
                responseType: 'token'
            }
        }
    }
}
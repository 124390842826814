import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import config from '../config';
import {setContext} from "@apollo/client/link/context";
//https://www.apollographql.com/docs/react/networking/authentication/

const createClient = (token) => {
    const httpLink = createHttpLink({
        uri: `https://${config.apiHostname}/graphql`
    });
    const authLink = setContext((_, {headers}) => {
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });
    return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });

    // return new ApolloClient({
    //     uri: 'http://localhost:1337/graphql',
    //     cache: new InMemoryCache()
    // });
}

export default createClient;
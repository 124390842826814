import React from 'react'
import Helmet from "react-helmet";
import config from '../config';

const SEO = ({title}) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title ? `${title} // ${config.siteTitle}` : config.siteTitle}</title>
            <meta name="description" content={config.siteDescription} />
            <link rel="canonical" href={`https://${config.webHostname}`} />
        </Helmet>
    )
}

export default SEO;
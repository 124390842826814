import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Chip from "@material-ui/core/Chip";
import {FaAws} from "react-icons/fa";
import {GrWorkshop} from 'react-icons/gr';
import ReactMarkdown from "react-markdown";
import {gql, useQuery} from "@apollo/client";
import {PDFObject} from 'react-pdfobject'
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ReactPlayer from "react-player";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import {Redirect} from "react-router-dom";
import SEO from "../SEO";
import CssBaseline from "@material-ui/core/CssBaseline";
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        height: '100%',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: '160px',
        height: '100%'
    },
    solutionTitle: {
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center'
    },
    tabPanelContainer: {
        height: '100%',
        width: '100%'
    }
}));

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function Solution({match}) {
    const classes = useStyles();
    const [currentMatchSolution, setCurrentMatchSolution] = useState(null);
    const [value, setValue] = useState(0);
    const [showFade, setShowFade] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const TabPanel = (props) => {
        const {children, value, index, ...other} = props;
        return (
            <Fade in={showFade && value === index} timeout={800}>
                <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`vertical-tabpanel-${index}`}
                    aria-labelledby={`vertical-tab-${index}`}
                    style={{height: '100%', width: '100%'}}
                    {...other}
                >
                    {value === index && (
                        <Box pl={3}>
                            {children}
                        </Box>
                    )}
                </div>
            </Fade>
        );
    };
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    useEffect(() => {
        console.log('Match change', match)
        if (!currentMatchSolution) {
            setCurrentMatchSolution(match.url);
            setShowFade(true);
        } else if (currentMatchSolution !== match.url) {
            setValue(0);
        }
    }, [match]);

    const {loading, data} = useQuery(gql`
        query {
            solution(id: ${match.params.id}){
                id
                title
                slug
                description
                content
                type
                cover {
                    formats
                }
                slides {
                    id
                    title
                    type
                    description
                    link
                    file {
                        id
                        name
                        ext
                        mime
                        url
                    }
                }
                battle_cards {
                    id
                    title
                    description
                    content
                }
                videos {
                    id
                    title
                    description
                    source
                    link
                    file {
                        id
                        name
                        ext
                        mime
                        url
                    }
                }
                demonstrations{
                    id
                    title
                    description
                    content
                }
            }
        }
    `)

    return loading ? null : data.solution ? <>
        <div className={classes.solutionTitle}>
            <SEO title={data.solution.title}/>
            <Typography bold display='inline' variant="h3"
                        style={{fontWeight: 'bold'}}>{data.solution.title}</Typography>
            {data.solution.type === 'AWS' &&
            <Chip size='medium' label="AWS Solution" avatar={<FaAws/>}
                  style={{marginLeft: '1em', paddingLeft: '.4rem'}}/>}
            {data.solution.type === 'Partner' &&
            <Chip size='medium' label="Partner Solution" avatar={<GrWorkshop/>}
                  style={{marginLeft: '1em', paddingLeft: '.4rem'}}/>}
        </div>
        <div className={classes.root}>
            <Grow in={showFade}>
                <Tabs
                    indicatorColor='primary'
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    className={classes.tabs}
                >
                    <Tab label="Introduction" {...a11yProps(0)} />
                    <Tab label="Battle Cards" {...a11yProps(1)} />
                    <Tab label="Videos" {...a11yProps(2)} />
                    <Tab label="Demonstration" {...a11yProps(3)} />
                    <Tab label="Slides" {...a11yProps(4)} />
                </Tabs>
            </Grow>

            <TabPanel value={value} index={0}>
                <div className="content-markdown">
                    <CssBaseline/>
                    <ReactMarkdown source={data.solution.content}/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container spacing={3}>
                    {data.solution.battle_cards.length ? data.solution.battle_cards.map((battleCard, i) => (
                        <Grid item xs={4} key={i}>
                            <Card style={{maxWidth: '100%', display: 'flex'}}>
                                <CardActionArea>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {battleCard.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {battleCard.description}
                                        </Typography>
                                        <Box pt={1}>
                                            <ReactMarkdown source={battleCard.content}/>
                                        </Box>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )) : <Grid item><Typography variant="h6">Sorry, there isn't any battle card available for this
                        solutions.</Typography></Grid>}
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid container spacing={3}>
                    {data.solution.videos.length ? data.solution.videos.map((video, i) => (
                            <Grid item xs={6} key={i}>
                                <Card style={{maxWidth: 1200}}>
                                    <CardContent>
                                        <Typography component="h5" variant="h4">{video.title}</Typography>
                                        {video.description &&
                                        <Typography color="textSecondary" component="p">
                                            {video.description}
                                        </Typography>}
                                    </CardContent>
                                    <div style={{position: 'relative', paddingTop: '56.25%'}}>
                                        <ReactPlayer style={{position: 'absolute', top: 0, left: 0}} controls={true}
                                                     url={video.source === 'File' ? video.file.url : video.link}
                                                     width="100%"
                                                     height="100%"/>
                                    </div>
                                </Card>
                            </Grid>
                        ))
                        :
                        <Grid item><Typography variant="h6">Sorry, there isn't any video available for this
                            solutions.</Typography></Grid>
                    }
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Grid container spacing={3}>
                    {data.solution.demonstrations && data.solution.demonstrations.length ?
                        data.solution.demonstrations.map((demonstration, i) => (
                            <Grid item xs={12} key={i}>
                                <Card>
                                    <CardContent>
                                        <Typography component="h5" variant="h4">{demonstration.title}</Typography>
                                        <Typography component="p"
                                                    variant="textSecondary">{demonstration.description}</Typography>
                                        <Typography color="body" component="p">
                                            {!!demonstration.content ?
                                                <ReactMarkdown source={demonstration.content}/> : null}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )) :
                        <Typography variant="h6">Sorry, there isn't any demonstration available for this
                            solutions.</Typography>}
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Grid container spacing={3}>
                    {data.solution.slides.length ? data.solution.slides.filter(slide => slide.type === 'Link').map((slide, i) => (
                        <Grid item xs={4} key={i}>
                            <Card>
                                <CardActionArea>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">{slide.title}</Typography>
                                        {!!slide.description ? <Typography variant="body2" color="textSecondary"
                                                                           component="p">{slide.description}</Typography> : null}
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button size="small" color="primary" variant="contained"
                                            href={slide.link}>More</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    )) : null}
                    {data.solution.slides.length ? data.solution.slides.filter(slide => slide.type === 'File').map((slide, i) =>
                            <Grid item xs={12} key={i}><Box><PDFObject
                                containerId={`slide-${i}`} url={slide.file.url}
                                height='calc(100vh * 0.75)'/></Box></Grid>
                        )
                        :
                        <Typography variant="h6">Sorry, there isn't any slide available for this
                            solutions.</Typography>
                    }
                </Grid>
            </TabPanel>
        </div>
    </> : <Redirect to="/404"/>
}
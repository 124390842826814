import React from "react";
import MLink from "@material-ui/core/Link";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
        root: {
            fontFamily: '"Nunito",AmazonEmber,Helvetica,Arial,sans-serif',
            fontWeight: '800',
            flexGrow: 1
        },
        demo: {
            color: '#25424C'
        },
        hub: {
            color: '#FF9900'
        }
    }
);

function Header({size = 'h4', href = '/', align = 'inherit', display = 'initial'}) {
    const classes = useStyles();
    return (
        <Typography noWrap variant={size} className={classes.root} align={align} display={display}>
            <MLink component={Link} underline={'none'} to={href}>
                <span className={classes.demo}>DEMO</span>
                <span className={classes.hub}>HUB</span>
            </MLink>
        </Typography>
    )
}

export default Header;